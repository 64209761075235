import { createSlice } from "@reduxjs/toolkit";
import { getPatients, postPatient, upvotePatient } from "./patientsActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  patientsData: {},
  singlePatient: null,
};

const patientsSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    resetPatientsError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatients.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPatients.fulfilled, (state, action) => {
        state.loading = false;
        state.patientsData = action.payload;
      })
      .addCase(getPatients.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(postPatient.pending, (state) => {
        state.loading = true;
      })
      .addCase(postPatient.fulfilled, (state, action) => {
        state.patientsData = {
          meta: state?.patientsData?.meta,
          results: [action?.payload, ...(state?.patientsData?.results || [])],
        };
        state.loading = false;
        state.success = true;
      })
      .addCase(postPatient.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(upvotePatient.pending, (state) => {
        state.loading = true;
      })
      .addCase(upvotePatient.fulfilled, (state, action) => {
        const updatedPatients = state?.patientsData?.results?.map((patient) =>
          patient?.id?.toString() === action?.payload?.reported_user?.toString()
            ? { ...patient, upvotes: patient?.upvotes + 1 }
            : patient
        );
        const updated_patients_data = {
          meta: state.patientsData?.meta,
          results: updatedPatients,
        };
        state.patientsData = updated_patients_data;
        state.loading = false;
      })
      .addCase(upvotePatient.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { resetPatientsError } = patientsSlice.actions;
export default patientsSlice.reducer;
