
import RolesHelper from "./RolesHelper";

const CanDoAction = (props) => {
  const { children } = props;
  const will_return_children = RolesHelper?.help_determine_via_role();

  return will_return_children ? children : null;
};
export default CanDoAction;
