import React, { useState, useCallback } from "react";
import "./Details.scss";
import { Divider, Modal, Table, Typography, notification } from "antd";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { CommentSection, DetailsHeader } from "./components";
import SignIn from "../SignIn/SignIn";
import { useSelector, useDispatch } from "react-redux";
import { resetPatientsError } from "../../features/patients/patientsSlice";
import Utils from "../../utils";
import {
  resetComments,
  resetCommentsError,
} from "../../features/comments/commentsSlice";

export default function Details({
  token,
  patient,
  isOpened,
  onOk,
  onCancel,
  setSelectedPatient,
}) {
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = useCallback(
    (type, title, text) => {
      api[type]({
        message: title,
        description: text,
        placement: "top",
      });
    },
    [api]
  );
  const { isMobile } = useWindowDimensions();
  const { error: patientError } = useSelector((state) => state?.patients);
  const { error: commentsError } = useSelector((state) => state?.comments);
  const dispatch = useDispatch();

  const [is_signin_opened, set_is_signin_opened] = useState(false);

  const tableColumns = [
    {
      title: "Phone",
      dataIndex: "phone_number",
      key: "phone_number",
      width: 145,
      render: (_, record) => {
        return record?.phone_number ? (
          <Typography.Text>{`${Utils?.mask_phone_number(
            record?.phone_number
          )}`}</Typography.Text>
        ) : (
          ""
        );
      },
    },
    {
      title: "Date of Birth",
      dataIndex: "dob",
      key: "dob",
      width: 100,
      render: (_, record) => {
        return record?.dob ? Utils?.format_date(record?.dob) : "";
      },
    },
    {
      title: "Last Ordered",
      dataIndex: "last_order_date",
      key: "last_order_date",
      width: 160,
      render: (_, record) => {
        return record?.last_order_date
          ? Utils?.format_date_time(record?.last_order_date)
          : "";
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: isMobile ? 400 : "auto",
      ellipsis: true,
      render: (_, record) => {
        return record?.address ? (
          <Typography.Link
            target="_blank"
            href={`${Utils?.getUrlInMap(record?.address)}`}>
            {record?.address}
          </Typography.Link>
        ) : (
          ""
        );
      },
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: 90,
      ellipsis: true,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: 100,
      ellipsis: true,
    },
    {
      title: "Zip",
      dataIndex: "zipcode",
      key: "zipcode",
      width: 90,
      ellipsis: true,
    },
  ];

  const updatePatient = (patient) => {
    setSelectedPatient(patient);
  };

  return (
    <>
      {contextHolder}
      <Modal
        style={{
          borderTop: `2px solid ${token?.colorPrimary}`,
        }}
        width={isMobile ? 600 : 1150}
        className="details-cont"
        open={isOpened}
        centered={true}
        destroyOnClose={true}
        onCancel={() => {
          onCancel();
          if (patientError) {
            dispatch(resetPatientsError());
          }
          if (commentsError) {
            dispatch(resetCommentsError());
          }
          dispatch(resetComments());
        }}
        footer={null}
        onOk={() => {
          onOk();
        }}>
        <div className="details-cont__content">
          <DetailsHeader
            patient={patient}
            updatePatient={updatePatient}
            isMobile={isMobile}
            set_is_signin_opened={set_is_signin_opened}
            openNotificationWithIcon={openNotificationWithIcon}
          />
          <Table
            className="details-cont__content__table"
            size="small"
            columns={tableColumns}
            dataSource={[{ ...patient, key: patient?.id }]}
            bordered
            responsive
            pagination={false}
            scroll={{
              x: `${750}px`,
            }}
          />
          <Divider style={{ marginTop: "12px", marginBottom: "12px" }} />
          <CommentSection
            token={token}
            patient={patient}
            set_is_signin_opened={set_is_signin_opened}
            isDetailOpened={isOpened}
            openNotificationWithIcon={openNotificationWithIcon}
          />
        </div>
        <SignIn
          token={token}
          isOpened={is_signin_opened}
          onCancel={() => {
            set_is_signin_opened(false);
          }}
        />
      </Modal>
    </>
  );
}
