import LocalStorageService from "../LocalStorageService";

const RolesHelper = {
  help_determine_via_role: () => {
    const authData = LocalStorageService?.get("authorizationData");
    const is_organization = Boolean(authData?.is_organization) || false;
    return is_organization;
  },
};

export default RolesHelper;
