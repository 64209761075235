import React from "react";
import "./DetailsHeader.scss";
import { Button, Image, Typography } from "antd";
import {
  CaretUpOutlined,
  // CommentOutlined,
  // ContainerOutlined,
  // ShareAltOutlined,
} from "@ant-design/icons";
import patientImage from "../../../../assets/default_image_patient.png";
import { useDispatch, useSelector } from "react-redux";
import { upvotePatient } from "../../../../features/patients/patientsActions";
import { resetPatientsError } from "../../../../features/patients/patientsSlice";
import Utils from "../../../../utils";

export default function DetailsHeader({
  isMobile,
  patient,
  updatePatient,
  set_is_signin_opened,
  openNotificationWithIcon,
}) {
  const { isAuthenticated, user } = useSelector((state) => state?.auth);
  const {
    patientsData: { results: patients },
    loading,
  } = useSelector((state) => state?.patients);
  const dispatch = useDispatch();

  const handleUpvotePatient = (params) => {
    dispatch(upvotePatient(params))?.then((res) => {
      if (res?.type === "upvote_patient/fulfilled") {
        const foundPatient = patients?.find(
          (storePatient) =>
            storePatient?.id?.toString() ===
            res?.payload?.reported_user?.toString()
        );
        updatePatient({ ...foundPatient, upvotes: foundPatient?.upvotes + 1 });
      } else if (res?.type === "upvote_patient/rejected") {
        const error = res?.payload;
        for (let key in error) {
          if (typeof key === "string" && typeof error[key] === "string") {
            openNotificationWithIcon("error", key, error[key]);
            break;
          }
        }
        dispatch(resetPatientsError());
      }
    });
  };

  return (
    <div className="detailsHeader-cont">
      <div>
        <Image
          style={{
            width: "60px",
            height: "60px",
          }}
          src={patient?.id_image_url ? patient?.id_image_url : patientImage}
          alt="p_img"
        />
      </div>
      <div className="detailsHeader-cont__detailsWrapper">
        <div className="detailsHeader-cont__detailsWrapper__titleWrapper">
          <Typography.Text className="detailsHeader-cont__detailsWrapper__titleWrapper__title">{`${
            patient?.first_name ? patient?.first_name : "First Name"
          } ${
            patient?.last_name ? patient?.last_name : "Last Name"
          }`}</Typography.Text>
          <Typography.Text type="secondary">{`${
            patient?.email ? Utils?.mask_email(patient?.email) : "Email"
          }`}</Typography.Text>
        </div>
        <Button
          className="detailsHeader-cont__detailsWrapper__btn"
          type="primary"
          loading={loading}
          onClick={() => {
            if (!isAuthenticated) {
              set_is_signin_opened(true);
            } else if (isAuthenticated && !user?.is_organization) {
              openNotificationWithIcon(
                "warning",
                "Warning",
                "Only organizations can upvote patient."
              );
            } else {
              handleUpvotePatient({
                reported_user: patient?.id,
              });
            }
          }}
          icon={
            <CaretUpOutlined
              style={{
                fontSize: "18px",
              }}
            />
          }>
          Upvote {patient?.upvotes ? patient?.upvotes : null}
        </Button>
      </div>
      <div className="detailsHeader-cont__reasonWrapper">
        <div className="detailsHeader-cont__reasonWrapper__optionsWrapper">
          <Typography.Text type="secondary">Free</Typography.Text>
          {/* {!isMobile ? (
            <div className="detailsHeader-cont__reasonWrapper__optionsWrapper__btnsWrapper">
              <Button type="text" size="small" icon={<CommentOutlined />}>
                Discuss
              </Button>
              <Button
                type="text"
                size="small"
                icon={<ContainerOutlined />}
                onClick={() => {
                  if (!isAuthenticated) {
                    set_is_signin_opened(true);
                  } else {
                    console.log("worked collect");
                  }
                }}>
                Collect
              </Button>
              <Button
                type="text"
                size="small"
                icon={<ShareAltOutlined />}
                onClick={() => {
                  if (!isAuthenticated) {
                    set_is_signin_opened(true);
                  } else {
                    console.log("worked share");
                  }
                }}>
                Share
              </Button>
            </div>
          ) : null} */}
        </div>
        <Typography.Text>
          {" "}
          {patient?.ban_reason ? patient?.ban_reason : "No Reason"}
        </Typography.Text>
        {/* {isMobile ? (
          <div className="detailsHeader-cont__reasonWrapper__optionsWrapper__btnsWrapper">
            <Button type="text" size="small" icon={<CommentOutlined />}>
              Discuss
            </Button>
            <Button type="text" size="small" icon={<ContainerOutlined />}>
              Collect
            </Button>
            <Button type="text" size="small" icon={<ShareAltOutlined />}>
              Share
            </Button>
          </div>
        ) : null} */}
      </div>
    </div>
  );
}
