import React, { useState, useEffect } from "react";
import { AppHeader, AppFooter } from "../../components";
import { SearchBar, PatientData } from "./components";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import LocalStorageService from "../../common/services/LocalStorageService";

export default function MainPage() {
  const [searchParams] = useSearchParams();
  const { isAuthenticated } = useSelector((state) => state?.auth);
  const is_searched =
    Boolean(searchParams?.get("name")) || searchParams?.get("step");
  const [is_on_selectWorks, set_is_on_selectWorks] = useState(false);

  useEffect(() => {
    if (!isAuthenticated && is_on_selectWorks) {
      let search_count = LocalStorageService?.get("search_count");
      LocalStorageService?.set("search_count", search_count + 1);
      set_is_on_selectWorks(false);
    }
  }, [is_on_selectWorks, isAuthenticated]);

  return (
    <>
      <AppHeader />
      {is_searched ? (
        <PatientData />
      ) : (
        <SearchBar set_is_on_selectWorks={set_is_on_selectWorks} />
      )}
      {!is_searched ? <AppFooter /> : null}
    </>
  );
}
