import React, { useRef } from "react";
import "./Login.scss";
import { Modal, Spin, Form, Typography, Input, Button } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { resetAuthError } from "../../features/auth/authSlice";
import Utils from "../../utils";

export default function Login({
  token,
  title,
  isOpened,
  onCancel,
  onOk,
  openRegister,
}) {
  const { error, loading } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const formRef = useRef(null);

  return (
    <Modal
      style={{
        borderTop: `2px solid ${token?.colorPrimary}`,
      }}
      className="login-cont"
      open={isOpened}
      title={title}
      centered={true}
      destroyOnClose={true}
      footer={null}
      onCancel={() => {
        formRef?.current?.resetFields();
        onCancel();
        if (error) {
          dispatch(resetAuthError());
        }
      }}>
      <Spin spinning={loading} size="large">
        <Form
          style={{
            maxWidth: 360,
          }}
          className="login-cont__form"
          ref={formRef}
          onFinish={(values) => {
            onOk(values, () => {
              formRef?.current?.resetFields();
              if (error) {
                dispatch(resetAuthError());
              }
            });
          }}
          onFinishFailed={() => {}}>
          <div className="login-cont__form__formItemWrapper">
            <Form.Item
              className="login-cont__form__formItemWrapper__formItem"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter email.",
                },
                {
                  validator: Utils?.emailValidator,
                },
              ]}>
              <Input
                size="large"
                prefix={<MailOutlined />}
                placeholder="Email"
                autoComplete="username"
                onChange={() => {
                  if (error) {
                    dispatch(resetAuthError());
                    formRef?.current?.setFields([
                      {
                        name: "password", // Field name you want to set the error for
                        errors: [], // Assuming your error has a 'message' field
                      },
                    ]);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="login-cont__form__formItemWrapper__formItem"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password.",
                },
              ]}>
              <Input.Password
                size="large"
                prefix={<LockOutlined />}
                placeholder="Password"
                autoComplete="current-password"
                onChange={() => {
                  if (error) {
                    dispatch(resetAuthError());
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="login-cont__form__btnWrapper">
            <Button
              block
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading}
              disabled={error}>
              Log in
            </Button>
            <div className="login-cont__form__btnWrapper__textWrapper">
              <Typography.Text strong>Or</Typography.Text>
              <Typography.Text
                style={{
                  color: token?.colorPrimary,
                }}
                className="login-cont__form__clickableText"
                strong
                onClick={() => {
                  onCancel();
                  openRegister();
                  if (error) {
                    dispatch(resetAuthError());
                  }
                }}>
                Register Now
              </Typography.Text>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
}
