import React, { useState } from "react";
import "./Register.scss";
import { Avatar, Button, Badge, Popover, Divider } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { logOut } from "../../../../features/auth/authSlice";
import {
  SignIn,
  ConfirmModal,
  RegisterOrganization,
  Login,
} from "../../../../modals";

export default function Register({ token, width, isMobile, is_searched }) {
  const { isAuthenticated, user } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const [isPopOverOpened, setIsPopOverOpened] = useState(false);
  const [is_signin_opened, set_is_signin_opened] = useState(false);
  const [is_confirm_modal_opened, set_is_confirm_modal_opened] =
    useState(false);

  const [register_organization, setRegister_organization] = useState(false);
  const [login_organization, setLogin_organization] = useState(false);

  const handleLogOut = () => {
    dispatch(logOut());
    set_is_confirm_modal_opened(false);
  };

  const registerOrganization = (params, cb) => {
    console.log("register", params);
    setRegister_organization(false);
    if (typeof cb === "function") {// TO_DO call calback function when register request will be fulfilleded
      cb();
    }
  };

  const loginOrganization = (params, cb) => {
    console.log("Login", params);
    setLogin_organization(false);
    if (typeof cb === "function") {// TO_DO call calback function when login request will be fulfilleded
      cb();
    }
  };

  return (
    <div
      className="register-cont"
      style={
        !is_searched && isMobile
          ? {
              transform: "translate(-20px, -60%)",
            }
          : null
      }>
      {isAuthenticated ? (
        <div
          className="register-cont__btnsWrapper"
          style={{
            marginRight: "6px",
          }}>
          <Popover
            placement="bottomLeft"
            trigger="click"
            open={isPopOverOpened}
            onOpenChange={() => {
              setIsPopOverOpened(!isPopOverOpened);
            }}
            content={
              <div className="register-cont__btnsWrapper__popOverContent">
                <Button
                  type="text"
                  className="register-cont__btnsWrapper__popOverContent__btn"
                  onClick={() => {
                    setIsPopOverOpened(false);
                  }}>
                  {`${user?.first_name ? user?.first_name : ""} ${
                    user?.last_name ? user?.last_name : ""
                  }`}
                </Button>
                <Divider
                  type="horizontal"
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                    width: "100%",
                  }}
                />
                <Button
                  danger
                  type="text"
                  className="register-cont__btnsWrapper__popOverContent__btn"
                  onClick={() => {
                    setIsPopOverOpened(false);
                    set_is_confirm_modal_opened(true);
                  }}>
                  Sign Out
                </Button>
              </div>
            }>
            <Badge
              size={isMobile ? "small" : "default"}
              dot
              styles={{
                indicator: {
                  background: token?.colorPrimary,
                },
              }}>
              <Avatar
                shape="square"
                style={{
                  backgroundColor: token?.Avatar?.colorBgAvatar,
                  cursor: "pointer",
                }}
                src={user?.picture_url ? user?.picture_url : ""}
                size={24}
                icon={
                  <UserOutlined
                    style={{
                      color: `${token?.icons?.whiteIcon}`,
                      fontSize: "18px",
                    }}
                  />
                }
              />
            </Badge>
          </Popover>
        </div>
      ) : (
        <div className="register-cont__btnsWrapper">
          <Button
            type="primary"
            size={width <= 370 ? "small" : isMobile ? "middle" : "large"}
            style={{
              width: width <= 370 ? "55px" : "auto",
            }}
            shape="round"
            onClick={() => {
              set_is_signin_opened(true);
            }}>
            Sign In
          </Button>
        </div>
      )}
      <SignIn
        token={token}
        isOpened={is_signin_opened}
        onCancel={() => {
          set_is_signin_opened(false);
        }}
        setRegister_organization={setRegister_organization}
        setLogin_organization={setLogin_organization}
      />
      <RegisterOrganization
        token={token}
        title={"Register Organization"}
        isOpened={register_organization}
        onCancel={() => {
          setRegister_organization(false);
        }}
        onOk={registerOrganization}
      />
      <Login
        token={token}
        title={"Sign In With Organization"}
        isOpened={login_organization}
        onCancel={() => {
          setLogin_organization(false);
        }}
        onOk={loginOrganization}
        openRegister={() => {
          setRegister_organization(true);
        }}
      />
      <ConfirmModal
        token={token}
        title={"Are you sure"}
        subTitle={"You want to log out ?"}
        icon={
          <LogoutOutlined
            style={{ fontSize: "24px", transform: "rotate(180deg)" }}
          />
        }
        isOpened={is_confirm_modal_opened}
        onCancel={() => {
          set_is_confirm_modal_opened(false);
        }}
        onOk={() => {
          handleLogOut();
        }}
      />
    </div>
  );
}
