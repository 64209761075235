import React, { useRef } from "react";
import "./Advertisement.scss";
import {
  theme,
  Card,
  Carousel,
  Divider,
  Image,
  Typography,
  Button,
} from "antd";
import {
  ArrowRightOutlined,
  FacebookOutlined,
  GoogleOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  XOutlined,
} from "@ant-design/icons";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import DefaultImage from "../../assets/default_image_patient.png";
import AD1 from "../../assets/AD1.jpg";
import AD2 from "../../assets/AD2.jpg";
import AD3 from "../../assets/AD3.jpg";

export default function Advertisement() {
  const { useToken } = theme;
  const { token } = useToken();
  const { isMobile } = useWindowDimensions();
  const contRef = useRef(null);

  return (
    <div className="advertisement-cont" ref={contRef}>
      {!isMobile ? (
        <Divider
          className="advertisement-cont__divider"
          type="vertical"
          style={{
            margin: "0px",
            height: `${contRef?.current?.offsetHeight}px`,
          }}
        />
      ) : null}

      <div className="advertisement-cont__adsWraper">
        <Carousel
          className="advertisement-cont__adsWraper__carousel"
          arrows
          infinite={true}
          autoplay
          autoplaySpeed={5000}
          draggable
          dots={false}
          adaptiveHeight={true}
          waitForAnimate={true}>
          <div
            className="advertisement-cont__adsWraper__carousel__item"
            style={{ backgroundColor: "#364d79" }}>
            <img
              src={AD1}
              alt="AD1"
              style={{
                width: "100%",
                height: "160px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            />
          </div>
          <div
            className="advertisement-cont__adsWraper__carousel__item"
            style={{ backgroundColor: "#364d79" }}>
            <img
              src={AD2}
              alt="AD1"
              style={{
                width: "100%",
                height: "160px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            />
          </div>
          <div
            className="advertisement-cont__adsWraper__carousel__item"
            style={{ backgroundColor: "red" }}>
            <img
              src={AD3}
              alt="AD1"
              style={{
                width: "100%",
                height: "160px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            />
          </div>
        </Carousel>
        <Card
          className="advertisement-cont__adsWraper__card"
          hoverable
          style={{
            border: "none",
            backgroundColor: token?.Card?.colorBgCard,
            borderTop: `2px solid ${token?.colorPrimary}`,
            boxShadow:
              "0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)",
          }}
          styles={{
            body: {
              width: "100%",
              display: "flex",
              gap: "10px",
              padding: "10px 0",
            },
          }}
          onClick={() => {
            window.open("https://www.google.com/", "_blank");
          }}>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{
              width: "50px",
              height: "50px",
            }}>
            <Image
              src={DefaultImage}
              alt="def_img"
              width="50px"
              height="50px"
            />
          </div>
          <div className="advertisement-cont__adsWraper__card__descriptionCont">
            <Typography.Text>
              This product offers top-notch quality and durability, designed to
              meet your needs with style and functionality. Perfect for everyday
              use or special occasions!
            </Typography.Text>
            <Typography.Link href="https://www.google.com/" target="_blank">
              Get it Now{" "}
              <ArrowRightOutlined
                style={{
                  fontSize: "14px",
                }}
              />
            </Typography.Link>
          </div>
        </Card>
        <div className="advertisement-cont__adsWraper__media">
          <Typography.Text
            className="advertisement-cont__adsWraper__media__title"
            strong
            ellipsis={{
              tooltip: {
                title: `Follow Us Online`,
              },
            }}>
            Follow Us Online
          </Typography.Text>
          <div className="advertisement-cont__adsWraper__media__wraper">
            <div className="advertisement-cont__adsWraper__media__wraper__cont">
              <Button
                className="advertisement-cont__adsWraper__media__wraper__cont__btn"
                size="middle"
                icon={<GoogleOutlined style={{ color: "#DB4437" }} />}
              />
              <Typography.Text
                ellipsis={{
                  tooltip: {
                    title: `Google`,
                  },
                }}>
                Google
              </Typography.Text>
            </div>
            <div className="advertisement-cont__adsWraper__media__wraper__cont">
              <Button
                className="advertisement-cont__adsWraper__media__wraper__cont__btn"
                size="middle"
                icon={<FacebookOutlined style={{ color: "#1877F2" }} />}
              />
              <Typography.Text
                ellipsis={{
                  tooltip: {
                    title: `Facebook`,
                  },
                }}>
                Facebook
              </Typography.Text>
            </div>
            <div className="advertisement-cont__adsWraper__media__wraper__cont">
              <Button
                className="advertisement-cont__adsWraper__media__wraper__cont__btn"
                size="middle"
                icon={<XOutlined style={{ color: "#000000" }} />}
              />
              <Typography.Text
                ellipsis={{
                  tooltip: {
                    title: `X (Twitter)`,
                  },
                }}>
                X (Twitter)
              </Typography.Text>
            </div>
            <div className="advertisement-cont__adsWraper__media__wraper__cont">
              <Button
                className="advertisement-cont__adsWraper__media__wraper__cont__btn"
                size="middle"
                icon={<LinkedinOutlined style={{ color: "#0077B5" }} />}
              />
              <Typography.Text
                ellipsis={{
                  tooltip: {
                    title: `Linkedin`,
                  },
                }}>
                Linkedin
              </Typography.Text>
            </div>
            <div className="advertisement-cont__adsWraper__media__wraper__cont">
              <Button
                className="advertisement-cont__adsWraper__media__wraper__cont__btn"
                size="middle"
                icon={<InstagramOutlined style={{ color: "#f58529" }} />}
              />
              <Typography.Text
                ellipsis={{
                  tooltip: {
                    title: `Instagram`,
                  },
                }}>
                Insatgram
              </Typography.Text>
            </div>
          </div>
        </div>
        {isMobile ? (
          <Divider
            className="advertisement-cont__divider"
            style={{
              margin: "0px",
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
