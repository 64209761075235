import React, { useRef, useState } from "react";
import "./RegisterOrganization.scss";
import {
  Modal,
  Form,
  Typography,
  Input,
  Spin,
  Button,
  Upload,
  Progress,
  message,
  Divider,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { PhoneNumberInput } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { resetAuthError } from "../../features/auth/authSlice";
import config from "../../config";
import axiosClient from "../../common/api/request";
import Utils from "../../utils";

export default function RegisterOrganization({
  token,
  title,
  isOpened,
  onCancel,
  onOk,
}) {
  const { error, loading } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [picture_url, setPicture_url] = useState(null);
  const [progress, setProgress] = useState(0);

  const postImage = async (formData) => {
    const { onSuccess, onError, file, onProgress } = formData;
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    const newFormData = new FormData();
    newFormData.append("image", file);
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/images/`,
        newFormData,
        {
          config,
          onUploadProgress: (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setProgress(percent);
            if (percent === 100) {
              setTimeout(() => setProgress(0), 1000);
            }
            onProgress({ percent: (event.loaded / event.total) * 100 });
          },
        }
      );
      setPicture_url({
        status: "done",
        id: data?.id,
        url: data?.image,
        uid: file?.uid,
        name: file?.name,
      });
      setProgress(0);
      onSuccess("Ok");
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        const errorKeys = Object.keys(error.response.data);
        const firstErrorKey = errorKeys.length > 0 ? errorKeys[0] : null;
        const errorMessage = firstErrorKey
          ? error?.response?.data[firstErrorKey]
          : "An error occurred.";
        formRef?.current?.setFields([
          {
            name: " picture_url",
            errors: [errorMessage],
          },
        ]);
        onError({ errorMessage });
      } else {
        onError({ message });
        formRef?.current?.setFields([
          {
            name: " picture_url",
            errors: [message],
          },
        ]);
      }
    }
  };

  const handleIdImageChange = (info) => {
    if (info?.file?.status === "removed") {
      setPicture_url(null);
      setProgress(0);
      formRef?.current?.setFields([
        {
          name: "picture_url",
          errors: [],
        },
      ]);
    } else if (
      info?.file?.status !== "removed" &&
      info?.file?.status !== "done"
    ) {
      setPicture_url({
        uid: info?.file?.originFileObj?.uid,
        name: info?.file?.originFileObj?.name,
        status: info?.file?.status,
      });
    }
  };

  return (
    <Modal
      style={{
        borderTop: `2px solid ${token?.colorPrimary}`,
      }}
      className="registerOrg-cont"
      open={isOpened}
      title={title}
      centered={true}
      destroyOnClose={true}
      onCancel={() => {
        formRef?.current?.resetFields();
        onCancel();
        setPicture_url(null);
        setProgress(0);
        if (error) {
          dispatch(resetAuthError());
        }
      }}
      footer={null}>
      <Spin spinning={loading} size="large">
        <Form
          className="registerOrg-cont__form"
          ref={formRef}
          layout="vertical"
          onFinish={(values) => {
            const modifiedValues = {
              ...values,
              picture_url: picture_url?.url ? picture_url?.url : null,
            };
            onOk(modifiedValues, () => {
              formRef?.current?.resetFields();
              setPicture_url(null);
              setProgress(0);
              if (error) {
                dispatch(resetAuthError());
              }
            });
          }}
          onFinishFailed={() => {}}>
          <div className="registerOrg-cont__form__formItemWrapper">
            <Form.Item
              className="registerOrg-cont__form__formItemWrapper__formItem"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter your organization name.",
                },
              ]}
              label={<Typography.Text>Organization Name</Typography.Text>}
              colon={false}>
              <Input
                type="text"
                placeholder="Organization Name"
                onChange={() => {
                  if (error && error["name"]) {
                    dispatch(resetAuthError("name"));
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="registerOrg-cont__form__formItemWrapper__formItem"
              name="contact_name"
              rules={[
                {
                  required: true,
                  message: "Please enter your contact name.",
                },
              ]}
              label={<Typography.Text>Contact Name</Typography.Text>}
              colon={false}>
              <Input
                type="text"
                placeholder="Contact Name"
                onChange={() => {
                  if (error && error["contact_name"]) {
                    dispatch(resetAuthError("contact_name"));
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="registerOrg-cont__form__formItemWrapper">
            <Form.Item
              className="registerOrg-cont__form__formItemWrapper__formItem"
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: "Please enter your phone number.",
                },
              ]}
              label={<Typography.Text>Phone Number</Typography.Text>}
              colon={false}>
              <PhoneNumberInput
                onChange={() => {
                  if (error && error["phone_number"]) {
                    dispatch(resetAuthError("phone_number"));
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="registerOrg-cont__form__formItemWrapper__formItem"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter email.",
                },
                {
                  validator: Utils?.emailValidator,
                },
              ]}
              label={<Typography.Text>Email</Typography.Text>}
              colon={false}>
              <Input
                type="text"
                placeholder="Email"
                onChange={() => {
                  if (error && error["email"]) {
                    dispatch(resetAuthError("email"));
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="registerOrg-cont__form__formItemWrapper">
            <Form.Item
              className="registerOrg-cont__form__formItemWrapper__formItem"
              name="license_number"
              rules={[
                {
                  required: true,
                  message: "Please enter your license number.",
                },
              ]}
              label={<Typography.Text>License Number</Typography.Text>}
              colon={false}>
              <Input
                type="text"
                placeholder="License Number"
                onChange={() => {
                  if (error && error["license_number"]) {
                    dispatch(resetAuthError("license_number"));
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="registerOrg-cont__form__formItemWrapper__formItem"
              name="address1"
              rules={[
                {
                  required: true,
                  message: "Please enter your buisness address.",
                },
              ]}
              label={<Typography.Text>Buisness Address</Typography.Text>}
              colon={false}>
              <Input
                type="text"
                placeholder="Buisness Address"
                onChange={() => {
                  if (error && error["address1"]) {
                    dispatch(resetAuthError("address1"));
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="registerOrg-cont__form__formItemWrapperUpload">
            <Form.Item
              className="registerOrg-cont__form__formItemWrapperUpload__formItem"
              name="picture_url">
              <div>
                <Upload
                  className="registerOrg-cont__form__formItemWrapperUpload__formItem__upload"
                  block
                  style={{
                    width: "100%",
                  }}
                  customRequest={postImage}
                  maxCount={1}
                  showUploadList={picture_url ? true : false}
                  accept="image/png, image/jpeg"
                  name="image"
                  fileList={picture_url ? [picture_url] : []}
                  onChange={handleIdImageChange}>
                  {picture_url || progress > 0 ? null : (
                    <Button
                      className="registerOrg-cont__form__formItemWrapperUpload__formItem__upload__btn"
                      type="default"
                      size="small"
                      block
                      icon={<UploadOutlined />}>
                      Upload Picture
                    </Button>
                  )}
                </Upload>
                {progress > 0 ? (
                  <Progress
                    percent={progress}
                    status={picture_url?.status === "error" ? "exception" : ""}
                  />
                ) : null}
              </div>
            </Form.Item>
          </div>
          <Divider
            style={{
              margin: "8px 0",
            }}
            type="horizontal"
          />
          <div className="registerOrg-cont__form__formItemWrapper">
            <Form.Item
              className="registerOrg-cont__form__formItemWrapper__formItem"
              name="address2"
              label={<Typography.Text>Address Line 2</Typography.Text>}
              colon={false}>
              <Input
                type="text"
                placeholder="Address Line 2"
                onChange={() => {
                  if (error && error["address2"]) {
                    dispatch(resetAuthError("address2"));
                    formRef?.current?.setFields([
                      {
                        name: "address2",
                        errors: [], // Clear the error
                      },
                    ]);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="registerOrg-cont__form__formItemWrapper__formItem"
              name="state"
              label={<Typography.Text>State</Typography.Text>}
              colon={false}>
              <Input
                type="text"
                placeholder="State"
                onChange={() => {
                  if (error && error["state"]) {
                    dispatch(resetAuthError("state"));
                    formRef?.current?.setFields([
                      {
                        name: "state",
                        errors: [], // Clear the error
                      },
                    ]);
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="registerOrg-cont__form__formItemWrapper">
            <Form.Item
              className="registerOrg-cont__form__formItemWrapper__formItem"
              name="city"
              label={<Typography.Text>City</Typography.Text>}
              colon={false}>
              <Input
                type="text"
                placeholder="City"
                onChange={() => {
                  if (error && error["city"]) {
                    dispatch(resetAuthError("city"));
                    formRef?.current?.setFields([
                      {
                        name: "city",
                        errors: [], // Clear the error
                      },
                    ]);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="registerOrg-cont__form__formItemWrapper__formItem"
              name="zipcode"
              label={<Typography.Text>Zip Code</Typography.Text>}
              colon={false}>
              <Input
                type="text"
                placeholder="Zip Code"
                onChange={() => {
                  if (error && error["zipcode"]) {
                    dispatch(resetAuthError("zipcode"));
                    formRef?.current?.setFields([
                      {
                        name: "zipcode",
                        errors: [], // Clear the error
                      },
                    ]);
                  }
                }}
              />
            </Form.Item>
          </div>
          <Button
            className="registerOrg-cont__form__btn"
            type="primary"
            block
            htmlType="submit"
            disabled={error ? Object.keys(error)?.length !== 0 : false}
            loading={loading}>
            Register
          </Button>
        </Form>
      </Spin>
    </Modal>
  );
}
