import React, { useState, useEffect, useCallback } from "react";
import "./PatientData.scss";
import { Content } from "antd/es/layout/layout";
import {
  theme,
  Button,
  List,
  Pagination,
  Typography,
  Image,
  Badge,
  Breadcrumb,
  notification,
} from "antd";
import {
  CaretUpOutlined,
  // StarOutlined,
  // LikeOutlined,
  // CommentOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { useSearchParams } from "react-router-dom";
import { CreatePatient, Details, SignIn } from "../../../../modals";
import patientImage from "../../../../assets/default_image_patient.png";
import {
  postPatient,
  upvotePatient,
} from "../../../../features/patients/patientsActions";
import { Advertisement } from "../../../../components";
import { resetPatientsError } from "../../../../features/patients/patientsSlice";
import CanDoAction from "../../../../common/services/Permissions/Can";
import Utils from "../../../../utils";

export default function PatientData() {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = useCallback(
    (type, title, text) => {
      api[type]({
        message: title,
        description: text,
        placement: "top",
      });
    },
    [api]
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const { width, isMobile } = useWindowDimensions();
  const { isAuthenticated, user } = useSelector((state) => state?.auth);
  const {
    patientsData: { results: patients, meta },
    loading,
    error,
  } = useSelector((state) => state?.patients);
  const dispatch = useDispatch();
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [is_detail_opened, set_is_detail_opened] = useState(false);
  const [is_create_patient_opened, set_is_create_patient_opened] =
    useState(false);
  const [is_signin_opened, set_is_signin_opened] = useState(false);

  const setUrlSearchParams = (newParams) => {
    const filteredSearchParams = Utils?.get_filtered_url_params(searchParams, {
      name: searchParams?.get("name") ? searchParams?.get("name") : undefined,
      step: searchParams?.get("step") ? searchParams?.get("step") : undefined,
      page: searchParams?.get("page") ? searchParams?.get("page") : 1,
      per_page: searchParams?.get("per_page")
        ? searchParams?.get("per_page")
        : 10,
      ...newParams,
    });
    setSearchParams(filteredSearchParams);
  };

  const handlePagination = (e, a) => {
    setUrlSearchParams({
      page: e,
      per_page: a,
      name: searchParams?.get("name") || undefined,
    });
  };

  const addPatient = (params, callBack) => {
    dispatch(postPatient(params))?.then((res) => {
      if (res?.type === "post_patient/fulfilled") {
        set_is_create_patient_opened(false);
        if (typeof callBack === "function") {
          callBack();
        }
      }
    });
  };

  const handleUpvotePatient = (params) => {
    dispatch(upvotePatient(params));
  };

  useEffect(() => {
    if (
      searchParams?.get("step") &&
      !is_detail_opened &&
      !is_create_patient_opened
    ) {
      if (error && !loading) {
        if (typeof error === "object" && !Array.isArray(error)) {
          for (let key in error) {
            if (typeof key === "string" && typeof error[key] === "string") {
              openNotificationWithIcon("error", key, error[key]);
              break;
            }
          }
        } else {
          openNotificationWithIcon("error", "Error", "Something went wrong !");
        }
        dispatch(resetPatientsError());
      }
    }
  }, [
    error,
    loading,
    searchParams,
    openNotificationWithIcon,
    dispatch,
    is_detail_opened,
    is_create_patient_opened,
  ]);

  return (
    <>
      {contextHolder}
      <Content className="patientData-cont">
        <CanDoAction>
          <div className="patientData-cont__creation">
            <Button
              type="primary"
              onClick={() => {
                set_is_create_patient_opened(true);
              }}
              size="middle">
              Create New
            </Button>
          </div>
        </CanDoAction>
        <div className="patientData-cont__flexWrapper">
          <List
            className="patientData-cont__list"
            dataSource={Utils?.madeUnique(patients)}
            loading={loading}
            size="small"
            itemLayout="vertical"
            renderItem={(patient) => (
              <List.Item
                key={patient?.id}
                className="patientData-cont__list__item"
                style={{
                  cursor: "pointer",
                }}
                styles={{
                  actions: {
                    marginLeft: "0px",
                  },
                  extra: {
                    marginTop: "0px",
                    marginLeft: "8px",
                  },
                }}
                onClick={() => {
                  if (!isAuthenticated) {
                    set_is_signin_opened(true);
                  } else {
                    setSelectedPatient(patient);
                    set_is_detail_opened(true);
                  }
                }}
                extra={
                  <Badge
                    size="small"
                    color={token?.colorSecondaryLogo}
                    count={patient?.upvotes ? patient?.upvotes : 0}
                    styles={{
                      root: {
                        marginRight: "15px",
                        marginTop: "5px",
                      },
                    }}>
                    <Button
                      size={isMobile ? "small" : "middle"}
                      icon={
                        <CaretUpOutlined
                          style={{
                            fontSize: "18px",
                          }}
                        />
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isAuthenticated) {
                          set_is_signin_opened(true);
                        } else if (isAuthenticated && !user?.is_organization) {
                          openNotificationWithIcon(
                            "warning",
                            "Warning",
                            "Only organizations can upvote patient."
                          );
                        } else {
                          handleUpvotePatient({
                            reported_user: patient?.id,
                          });
                        }
                      }}
                    />
                  </Badge>
                }
                // actions={[
                //   <Button
                //     type="text"
                //     icon={<StarOutlined />}
                //     size="small"
                //     onClick={(e) => {
                //       e.stopPropagation();
                //       if (!isAuthenticated) {
                //         set_is_signin_opened(true);
                //       } else {
                //         console.log("woked StarOutlined");
                //       }
                //     }}
                //   />,
                //   <Button
                //     type="text"
                //     icon={<LikeOutlined />}
                //     size="small"
                //     onClick={(e) => {
                //       e.stopPropagation();
                //       if (!isAuthenticated) {
                //         set_is_signin_opened(true);
                //       } else {
                //         console.log("woked LikeOutlined");
                //       }
                //     }}
                //   />,
                //   <Button
                //     type="text"
                //     icon={<CommentOutlined />}
                //     size="small"
                //     onClick={(e) => {
                //       e.stopPropagation();
                //       if (!isAuthenticated) {
                //         set_is_signin_opened(true);
                //       } else {
                //         setSelectedPatient(patient);
                //         set_is_detail_opened(true);
                //       }
                //     }}
                //   />,
                // ]}
              >
                <List.Item.Meta
                  className="patientData-cont__list__item__meta"
                  avatar={
                    <div
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent List.Item's onClick from being triggered
                      }}>
                      <Image
                        style={{
                          width: "40px",
                          height: "50px",
                        }}
                        src={
                          patient?.id_image_url
                            ? patient?.id_image_url
                            : patientImage
                        }
                        alt="p_img"
                      />
                    </div>
                  }
                  title={
                    <Typography.Text
                      strong
                      ellipsis={
                        isMobile && width <= 350
                          ? {
                              tooltip: {
                                title: `${
                                  patient?.first_name ? patient?.first_name : ""
                                }
                      ${patient?.last_name ? patient?.last_name : ""},
                      ${
                        patient?.email ? Utils?.mask_email(patient?.email) : ""
                      }`,
                              },
                            }
                          : null
                      }>
                      {`${patient?.first_name ? patient?.first_name : ""}
                      ${patient?.last_name ? patient?.last_name : ""},
                      ${
                        patient?.email ? Utils?.mask_email(patient?.email) : ""
                      }`}
                    </Typography.Text>
                  }
                  description={
                    <div
                      style={{
                        cursor: "auto",
                        width: "fit-content",
                      }}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent List.Item's onClick from being triggered
                      }}>
                      <Breadcrumb
                        separator="•"
                        items={[
                          {
                            title: patient?.phone_number ? (
                              <Typography.Text type="secondary">
                                {`${Utils?.mask_phone_number(
                                  patient?.phone_number
                                )}`}
                              </Typography.Text>
                            ) : (
                              <Typography.Text type="secondary">
                                Phone
                              </Typography.Text>
                            ),
                            // href: patient?.phone_number
                            //   ? `tel:${patient?.phone_number}`
                            //   : "",
                          },
                          !isMobile
                            ? {
                                title: patient?.dob ? (
                                  <Typography.Text type="secondary">
                                    {Utils?.format_date(patient?.dob)}
                                  </Typography.Text>
                                ) : (
                                  <Typography.Text type="secondary">
                                    Date Of Birth
                                  </Typography.Text>
                                ),
                              }
                            : null,
                          {
                            title: patient?.address ? (
                              <a
                                href={`${Utils?.getUrlInMap(patient?.address)}`}
                                target="_blank"
                                rel="noopener noreferrer">
                                <Typography.Text
                                  className="patientData-cont__list__item__meta__address"
                                  ellipsis={{
                                    tooltip: {
                                      title: `${patient?.address}`,
                                    },
                                  }}
                                  style={
                                    isMobile && width > 350
                                      ? {
                                          whiteSpace: "normal", // Allow line breaks between words
                                          overflowWrap: "break-word", // Ensure long words break properly
                                          display: "inline", // Ensure the ellipsis works with the text
                                        }
                                      : null
                                  }>
                                  {patient?.address}
                                </Typography.Text>
                              </a>
                            ) : (
                              <Typography.Text type="secondary">
                                Address
                              </Typography.Text>
                            ),
                          },
                        ]?.filter((item) => item !== null)}
                      />
                    </div>
                  }
                />
                <Typography.Text>
                  {patient?.ban_reason ? patient?.ban_reason : "No Reason"}
                </Typography.Text>
              </List.Item>
            )}
          />
          <Advertisement />
        </div>
        {meta?.count > 10 ? (
          <Pagination
            className="patientData-cont__pagination"
            align="end"
            pageSize={searchParams?.get("per_page") || 10}
            current={searchParams?.get("page") || 1}
            defaultCurrent={1}
            total={meta?.count}
            responsive={true}
            onChange={handlePagination}
            showQuickJumper={width < 584 && width >= 577 ? false : true}
          />
        ) : null}
        <Details
          token={token}
          isOpened={is_detail_opened}
          patient={selectedPatient}
          setSelectedPatient={setSelectedPatient}
          onCancel={() => {
            set_is_detail_opened(false);
            setSelectedPatient(null);
          }}
          onOk={() => {
            set_is_detail_opened(false);
            setSelectedPatient(null);
          }}
        />
        <CreatePatient
          token={token}
          title={"Create New Patient"}
          isOpened={is_create_patient_opened}
          onCancel={() => {
            set_is_create_patient_opened(false);
          }}
          onOk={(params, callBack) => {
            addPatient(params, callBack);
          }}
        />
        <SignIn
          token={token}
          isOpened={is_signin_opened}
          onCancel={() => {
            set_is_signin_opened(false);
          }}
        />
      </Content>
    </>
  );
}
