import { createSlice } from "@reduxjs/toolkit";
import { getComments, postComment } from "./commentsActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  commentsData: {},
};
const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    resetCommentsError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
    resetComments: (state) => {
      state.commentsData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getComments.pending, (state) => {
        state.loading = true;
      })
      .addCase(getComments.fulfilled, (state, action) => {
        state.loading = false;
        state.commentsData = action.payload;
      })
      .addCase(getComments.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(postComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(postComment.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(postComment.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { resetCommentsError, resetComments } = commentsSlice.actions;
export default commentsSlice.reducer;
