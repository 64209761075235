import { formatPhoneNumber } from "react-phone-number-input";
import dayjs from "dayjs";

const Utils = {
  debounce: (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  },
  getUrlParamsAsObject: (searchParams) => {
    if (!searchParams) return {}; // Return empty object if searchParams is null or undefined
    return Object.fromEntries(searchParams?.entries());
  },
  get_filtered_url_params(old_url_params, new_url_params) {
    if (!old_url_params && !new_url_params) return {};
    const old_params_as_object = this?.getUrlParamsAsObject(old_url_params);
    const filtered_params_as_object = Object?.fromEntries(
      Object?.entries({ ...old_params_as_object, ...new_url_params })?.filter(
        ([_, value]) => Boolean(value) || value === 0
      )
    );
    return filtered_params_as_object;
  },
  madeUnique: (items) => {
    return [...new Map(items?.map((item) => [item["id"], item]))?.values()];
  },
  getUrlInMap: (address) => {
    return `http://maps.apple.com/?q=${encodeURIComponent(address)}`;
  },
  emailValidator: (_, value) => {
    return new Promise((resolve, reject) => {
      if (!value || /\S+@\S+\.\S+/?.test(value)) {
        return resolve();
      } else {
        reject("Please enter valid email.");
      }
    });
  },
  format_phone_number: (phone_number) => {
    return phone_number ? formatPhoneNumber(phone_number) : "";
  },
  format_date: (date) => {
    return date ? dayjs(date)?.format("YYYY-MM-DD") : "";
  },
  format_date_time: (date) => {
    return date ? dayjs(date)?.format("YYYY-MM-DD hh:mm A") : "";
  },
  getTimeByMeasurment: (inputDate) => {
    const now = dayjs(); // Current time
    const parsedDate = dayjs(inputDate); // Parse input date
    const secondsDifference = now?.diff(parsedDate, "second"); // Difference in seconds
    const minutesDifference = now?.diff(parsedDate, "minute"); // Difference in minutes
    const hoursDifference = now?.diff(parsedDate, "hour"); // Difference in hours

    if (secondsDifference < 60) {
      return `${secondsDifference}s`; // Show seconds if less than a minute
    } else if (minutesDifference < 60) {
      return `${minutesDifference}m`; // Show minutes if less than an hour
    } else if (hoursDifference < 24) {
      return `${hoursDifference}h`; // Show hours if less than a day
    }

    return parsedDate?.format("MMM D"); // Format date as "Jan 7" if 24+ hours
  },
  mask_email: (email) => {
    if (!email?.includes("@")) return "";
    const [username, domain] = email?.split("@");
    if (!domain?.includes(".")) return "";
    const maskedUsername =
      username[0] +
      "∗"?.repeat(Math.max(username?.length - 2, 1)) +
      username?.slice(-1);

      const [domainName, domainExt] = domain?.split(".");
      const maskedDomain = domainName[0] + "∗"?.repeat(domainName?.length - 1) + "." + domainExt;
      return maskedUsername + "@" + maskedDomain;
  },
  mask_phone_number: (phone_number) => {
    const country_code = phone_number?.slice(1, 2);
    const clean_number = phone_number?.replace(/[^\d+]/g, "");
    const digits = clean_number?.startsWith("+1")
      ? clean_number?.substring(2)
      : clean_number?.substring(1);
    const last_two_digits = digits?.substring(8, 10);
    return `+${country_code} (∗∗∗) ∗∗∗-∗∗${last_two_digits}`;
  },
};
export default Utils;
