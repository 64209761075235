import React, { useCallback, useEffect, useRef } from "react";
import "./SearchBar.scss";
import { Content } from "antd/es/layout/layout";
import { theme, AutoComplete, Input, Empty, notification, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { AppTitle } from "../../../../components";
import { useSearchParams } from "react-router-dom";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { useDispatch, useSelector } from "react-redux";
import { getPatients } from "../../../../features/patients/patientsActions";
import { resetPatientsError } from "../../../../features/patients/patientsSlice";
import LocalStorageService from "../../../../common/services/LocalStorageService";
import Utils from "../../../../utils";

export default function SearchBar({ set_is_on_selectWorks }) {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = useCallback(
    (type, title, text) => {
      api[type]({
        message: title,
        description: text,
        placement: "top",
      });
    },
    [api]
  );
  const { isAuthenticated } = useSelector((state) => state?.auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile } = useWindowDimensions();
  const dispatch = useDispatch();
  const {
    patientsData: { results: patients },
    error,
    loading,
  } = useSelector((state) => state?.patients);
  const inputRef = useRef(null);

  const setUrlSearchParams = (newParams) => {
    const filteredSearchParams = Utils?.get_filtered_url_params(searchParams, {
      name: searchParams?.get("name") ? searchParams?.get("name") : undefined,
      step: searchParams?.get("step") ? searchParams?.get("step") : undefined,
      ...newParams,
    });
    setSearchParams(filteredSearchParams);
  };

  const onSearch = Utils?.debounce((val, cancelDebounce) => {
    if (!cancelDebounce) {
      dispatch(
        getPatients({
          name: val ? val?.toLowerCase() : undefined,
        })
      );
      if (!isAuthenticated) {
        let search_count = LocalStorageService?.get("search_count");
        LocalStorageService?.set("search_count", search_count + 1);
      }
    }
  }, 500);

  const onSelect = (_, option) => {
    setUrlSearchParams({
      name: option?.label ? option?.label : undefined,
      step: 1,
    });
    if (inputRef?.current?.input?.value) {
      onSearch("", true);
    }
    if (!isAuthenticated) {
      set_is_on_selectWorks(true);
    }
  };

  useEffect(() => {
    dispatch(
      getPatients({
        name: inputRef?.current?.input?.value
          ? inputRef?.current?.input?.value
          : undefined,
      })
    );
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (!searchParams?.get("step")) {
      if (error && !loading) {
        if (typeof error === "object" && !Array.isArray(error)) {
          for (let key in error) {
            if (typeof key === "string" && typeof error[key] === "string") {
              openNotificationWithIcon("error", key, error[key]);
              break;
            }
          }
        } else {
          openNotificationWithIcon("error", "Error", "Something went wrong !");
        }
        dispatch(resetPatientsError());
      }
    }
  }, [error, loading, searchParams, openNotificationWithIcon, dispatch]);

  return (
    <>
      {contextHolder}
      <Content className="searchBar-cont">
        <div className="searchBar-cont__content">
          <AppTitle
            token={token}
            classNameCont="searchBar-cont__content__titeWrapper"
            classNameTitle="searchBar-cont__title"
          />
          <AutoComplete
            className="searchBar-cont__search"
            style={{ height: isMobile ? "32px" : "40px" }}
            disabled={
              LocalStorageService?.get("search_count") >= 5 && !isAuthenticated
            }
            options={Utils?.madeUnique(patients)?.map((patient) => ({
              label: `${patient?.first_name ? patient?.first_name : ""} ${
                patient?.last_name ? patient?.last_name : ""
              }`,
              value: `${patient?.first_name ? patient?.first_name : ""} ${
                patient?.last_name ? patient?.last_name : ""
              }`,
              key: `${patient?.id}`,
            }))}
            onSearch={onSearch}
            onSelect={onSelect}
            notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
            <Input
              style={{
                borderRadius: "24px",
                height: isMobile ? "32px" : "40px",
              }}
              ref={inputRef}
              size={isMobile ? "middle" : "large"}
              placeholder="Search"
              prefix={<SearchOutlined />}
              allowClear
              onPressEnter={(e) => {
                const value = e?.target?.value;
                if (
                  (e?.key === "Enter" || e?.code === "NumpadEnter") &&
                  value
                ) {
                  onSearch("", true);
                  setUrlSearchParams({
                    name: value ? value : undefined,
                    step: 1,
                  });
                  if (!isAuthenticated) {
                    set_is_on_selectWorks(true);
                  }
                }
              }}
            />
          </AutoComplete>
          <Button
            className="searchBar-cont__quickSearch"
            size={isMobile ? "middle" : "large"}
            disabled={
              LocalStorageService?.get("search_count") >= 5 && !isAuthenticated
            }
            shape="round"
            icon={<SearchOutlined />}
            onClick={() => {
              setUrlSearchParams({
                step: 1,
                name: inputRef?.current?.input?.value
                  ? inputRef?.current?.input?.value
                  : undefined,
              });
              if (inputRef?.current?.input?.value) {
                onSearch("", true);
              }
              if (!isAuthenticated) {
                let search_count = LocalStorageService?.get("search_count");
                LocalStorageService?.set("search_count", search_count + 1);
              }
            }}>
            Quick Search
          </Button>
        </div>
      </Content>
    </>
  );
}
