import React, { useEffect } from "react";
import "./App.scss";
import { ConfigProvider, Layout } from "antd";
import { theme } from "../config/theme";
import { Route, Routes } from "react-router-dom";
import { MainPage } from "../pages";
import { useDispatch, useSelector } from "react-redux";
import { getMe } from "../features/auth/authActions";

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getMe());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <ConfigProvider theme={theme}>
      <Layout className="App-cont">
        <Routes>
          <Route path="/" element={<MainPage />} />
        </Routes>
      </Layout>
    </ConfigProvider>
  );
}

export default App;
