import React, { useEffect, useState } from "react";
import "./AppHeader.scss";
import { theme, Layout, AutoComplete, Input, Empty } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Register } from "./components";
import AppTitle from "../AppTitle/AppTitle";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPatients } from "../../features/patients/patientsActions";
import { resetPatientsError } from "../../features/patients/patientsSlice";
import Utils from "../../utils";
import LocalStorageService from "../../common/services/LocalStorageService";

const { Header } = Layout;

const doSearch = (value, cb, cancelDebounce) => {
  if (!cancelDebounce) {
    cb({
      page: 1,
      per_page: 10,
      name: value ? value : undefined,
    });
  }
};
const debouncedSearch = Utils?.debounce(doSearch, 500);

export default function AppHeader() {
  const { useToken } = theme;
  const { token } = useToken();
  const [searchParams, setSearchParams] = useSearchParams();
  const is_searched =
    Boolean(searchParams?.get("name")) || searchParams?.get("step");
  const dispatch = useDispatch();
  const { width, isMobile } = useWindowDimensions();
  const {
    patientsData: { results: patients },
    error,
  } = useSelector((state) => state?.patients);
  const { isAuthenticated } = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const [searchVal, setSearchVal] = useState(searchParams?.get("name") || "");

  const setUrlSearchParams = (newParams) => {
    const filteredSearchParams = Utils?.get_filtered_url_params(searchParams, {
      name: searchParams?.get("name") ? searchParams?.get("name") : undefined,
      step: searchParams?.get("step") ? searchParams?.get("step") : undefined,
      page: searchParams?.get("page") ? searchParams?.get("page") : 1,
      per_page: searchParams?.get("per_page")
        ? searchParams?.get("per_page")
        : 10,
      ...newParams,
    });
    setSearchParams(filteredSearchParams);
    if (!isAuthenticated) {
      let search_count = LocalStorageService?.get("search_count");
      LocalStorageService?.set("search_count", search_count + 1);
    }
  };

  useEffect(() => {
    if (searchParams?.get("step")) {
      setSearchVal(searchParams?.get("name") || "");
      dispatch(
        getPatients({
          name: searchParams?.get("name"),
          page: searchParams?.get("page") || 1,
          per_page: searchParams?.get("per_page") || 10,
        })
      );
    }
  }, [searchParams, dispatch, isAuthenticated]);

  const onSearch = (value, cancelDebounce) => {
    if(!cancelDebounce){
      setSearchVal(value);
    }
    debouncedSearch(
      value,
      () => {
        setUrlSearchParams({
          page: 1,
          per_page: 10,
          name: value ? value : undefined,
        });
      },
      cancelDebounce
    );
  };

  const onSelect = (_, option) => {
    setSearchVal(option?.label ? option?.label : "");
    setUrlSearchParams({
      name: option?.label ? option?.label : undefined,
      page: 1,
      per_page: 10,
    });
    if (searchVal) {
      onSearch("", true);
    }
  };

  return (
    <Header
      className="appHeader-cont"
      style={
        is_searched && isMobile
          ? {
              height: "90px",
            }
          : null
      }>
      {searchParams?.get("name") || searchParams?.get("step") ? (
        <div className="appHeader-cont__left">
          <AppTitle
            token={token}
            classNameCont="appHeader-cont__left__titeWrapper"
            classNameTitle="appHeader-cont__title"
            onTitleClick={() => {
              if (error) {
                dispatch(resetPatientsError());
              }
              navigate("/");
            }}
          />
          <AutoComplete
            className="appHeader-cont__left__search"
            disabled={
              LocalStorageService?.get("search_count") >= 5 && !isAuthenticated
            }
            style={{ height: isMobile ? "32px" : "40px" }}
            options={Utils?.madeUnique(patients)?.map((patient) => ({
              label: `${patient?.first_name ? patient?.first_name : ""} ${
                patient?.last_name ? patient?.last_name : ""
              }`,
              value: `${patient?.first_name ? patient?.first_name : ""} ${
                patient?.last_name ? patient?.last_name : ""
              }`,
              key: `${patient?.id}`,
            }))}
            value={searchVal}
            onSearch={onSearch}
            onSelect={onSelect}
            onKeyDown={(e) => {
              const value = e?.target?.value;
              if (
                (e?.key === "Enter" || e?.code === "NumpadEnter") &&
                value &&
                value !== searchVal
              ) {
                setSearchVal(value);
                setUrlSearchParams({
                  name: value ? value : undefined,
                  page: 1,
                  per_page: 10,
                });
              }
            }}
            notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
            <Input
              style={{
                borderRadius: "24px",
                height: isMobile ? "32px" : "40px",
              }}
              size={isMobile ? "middle" : "large"}
              placeholder="Search"
              prefix={<SearchOutlined />}
              allowClear
            />
          </AutoComplete>
        </div>
      ) : null}
      <Register
        token={token}
        isMobile={isMobile}
        width={width}
        is_searched={is_searched}
      />
    </Header>
  );
}
