import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import axiosClient from "../../common/api/request";

export const getComments = createAsyncThunk(
  "get_comments",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/patients/${params?.id}/comments/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue({
          Error: error?.message,
        });
      }
    }
  }
);

export const postComment = createAsyncThunk(
  "post_comment",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/patients/${params?.id}/comments/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue({
          Error: error?.message,
        });
      }
    }
  }
);
