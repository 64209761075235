import React, { useCallback } from "react";
import "./SignIn.scss";
import { Button, Image, Modal, Spin, Typography, notification } from "antd";
import MagnifingGlass from "../../assets/magnifying_glass.jpg";
import { AppTitle } from "../../components";
import { ApartmentOutlined, AppleFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { resetAuthError } from "../../features/auth/authSlice";
import { GoogleLogin } from "@react-oauth/google";
import { userSignin } from "../../features/auth/authActions";

export default function SignIn({
  isOpened,
  onCancel,
  token,
  setRegister_organization,
  setLogin_organization,
}) {
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = useCallback(
    (type, title, text) => {
      api[type]({
        message: title,
        description: text,
        placement: "top",
      });
    },
    [api]
  );
  const { error, loading } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();

  return (
    <>
      {contextHolder}
      <Modal
        style={{
          borderTop: `2px solid ${token?.colorPrimary}`,
        }}
        className="signIn-cont"
        open={isOpened}
        centered={true}
        destroyOnClose={true}
        onCancel={() => {
          onCancel();
          if (error) {
            dispatch(resetAuthError());
          }
        }}
        footer={null}>
        <Spin spinning={loading} size="large">
          <div className="signIn-cont__content">
            <Image
              src={MagnifingGlass}
              alt="sign_in_p"
              width={100}
              height={100}
              preview={false}
            />
            <div className="signIn-cont__content__titlesWrapper">
              <Typography.Text className="signIn-cont__content__titlesWrapper__boldTitle">
                Sign In on
              </Typography.Text>
              <AppTitle
                token={token}
                classNameCont="signIn-cont__content__titlesWrapper__apptitleCont"
                classNameTitle="signIn-cont__content__titlesWrapper__boldTitle"
              />
            </div>
            <div className="signIn-cont__content__btnsWrapper">
              <GoogleLogin
                width="239px"
                cookiePolicy="single_host_origin"
                onSuccess={(data) => {
                  dispatch(userSignin(data))?.then((res) => {
                    if (res?.type === "auth/signin/fulfilled") {
                      onCancel();
                    } else if (res?.type === "auth/signin/rejected") {
                      const error = res?.payload;
                      if (typeof error === "object" && !Array.isArray(error)) {
                        for (let key in error) {
                          if (
                            typeof key === "string" &&
                            typeof error[key] === "string"
                          ) {
                            openNotificationWithIcon("error", key, error[key]);
                            break;
                          }
                        }
                      } else {
                        openNotificationWithIcon(
                          "error",
                          "Error",
                          "Something went wrong !"
                        );
                      }
                      dispatch(resetAuthError());
                    }
                  });
                }}
                onError={() => {
                  openNotificationWithIcon(
                    "error",
                    "Error",
                    "An error occurred during login. Please try again."
                  );
                }}
              />
              <Button
                style={{
                  width: "100%",
                  maxWidth: "239px",
                  height: "36px",
                }}
                iconPosition="end"
                icon={<AppleFilled />}
                size="middle"
                onClick={() => {
                  onCancel();
                }}>
                Sign In With Apple
              </Button>
              <Button
                style={{
                  width: "100%",
                  maxWidth: "239px",
                  height: "36px",
                }}
                iconPosition="end"
                icon={<ApartmentOutlined  />}
                size="middle"
                onClick={() => {
                  setLogin_organization(true);
                  onCancel();
                }}>
                Sign In With Organization
              </Button>
            </div>
            <div className="signIn-cont__content__registerWrapper">
              <Typography.Text strong>Or</Typography.Text>
              <Typography.Text
                style={{
                  color: token?.Typography?.colorPrimaryText,
                }}
                className="signIn-cont__content__registerWrapper__text"
                strong
                onClick={() => {
                  setRegister_organization(true);
                  onCancel();
                }}>
                Register Organization Now
              </Typography.Text>
            </div>
            <div className="signIn-cont__content__textWrapper">
              <Typography.Text
                className="signIn-cont__content__textWrapper__text"
                style={{
                  color: token?.Typography?.colorPrimaryText,
                }}>
                We'll never post to any of patient's data without their
                permission.
              </Typography.Text>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
}
