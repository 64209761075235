import React from "react";
import "./AppFooter.scss";
import { Layout, theme, Typography } from "antd";
const { Footer } = Layout;

export default function AppFooter() {
  const { useToken } = theme;
  const { token } = useToken();

  return (
    <Footer
      className="appFooter-cont"
      style={{
        borderTop: `1px solid ${token?.Layout?.footerBorderTopColor}`,
      }}>
      <div className="appFooter-cont__textWrapper">
        <Typography.Link
          href="https://ant.design"
          target="_blank"
          style={{
            color: token?.Typography?.colorPrimaryText,
          }}>
          About
        </Typography.Link>
        <Typography.Link
          href="https://ant.design"
          target="_blank"
          style={{
            color: token?.Typography?.colorPrimaryText,
          }}>
          Business
        </Typography.Link>
      </div>
      <div className="appFooter-cont__textWrapper">
        <Typography.Link
          href="https://ant.design"
          target="_blank"
          style={{
            color: token?.Typography?.colorSecondaryLogoText,
          }}>
          Privacy
        </Typography.Link>
        <Typography.Link
          href="https://ant.design"
          target="_blank"
          style={{
            color: token?.Typography?.colorSecondaryLogoText,
          }}>
          Terms
        </Typography.Link>
      </div>
    </Footer>
  );
}
