import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import LocalStorageService from "../../common/services/LocalStorageService";
import axiosClient from "../../common/api/request";

export const userSignin = createAsyncThunk(
  "auth/signin",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL.replace("/api/v1", "")}/auth-receiver/`,
        params,
        config
      );
      // store user's token in local storage
      LocalStorageService.set("authorizationData", {
        access: data.access,
        refresh: data.refresh,
      });
      return data;
    } catch (error) {
      if (error?.response && error?.response?.data?.detail) {
        return rejectWithValue(error.response.data?.detail);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getMe = createAsyncThunk(
  "me",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/me/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);