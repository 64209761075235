import { useEffect, useState } from "react";

const SMALL_DESKTOP_BREAKPOINT = 1080;
const MIDDLE_DESKTOP_BREAKPOINT = 1240;

const useWindowDimensions = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window?.innerHeight);
  const isSmallDesktop = width <= SMALL_DESKTOP_BREAKPOINT;
  const isMiddlesDesktop = width <= MIDDLE_DESKTOP_BREAKPOINT;
  const isMobile = width <= 900;
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return {
    width,
    isSmallDesktop,
    isMiddlesDesktop,
    height,
    isMobile
  };
};
export default useWindowDimensions;