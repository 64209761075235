import axios from "axios";
import config from "../../config";
import LocalStorageService from "../services/LocalStorageService";

const axiosClient = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

axiosClient.interceptors.request.use(
  (request) => {
    const token = LocalStorageService.get("authorizationData")?.access;
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    if (
      request?.url === `${config.API_URL}/images/` ||
      request?.url === `${config.API_URL.replace("/api/v1", "")}/auth-receiver/`
    ) {
      request.headers["Content-Type"] = "multipart/form-data";
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    if (response === undefined) {
      return Promise.reject(
        new Error("Incorrect response format, the server might be unavailable")
      );
    }
    return response.data;
  },
  (error) => {
    if (error.response?.status === 401) {
      LocalStorageService?.remove("authorizationData");
      if (window?.location?.pathname !== "/" || window?.location?.search) {
        window.location.href = "/";
      } else {
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
