import React, { useRef, useState, useEffect } from "react";
import "./CreatePatient.scss";
import {
  Modal,
  Form,
  Typography,
  Input,
  DatePicker,
  Upload,
  Progress,
  Button,
  message,
  Divider,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { PhoneNumberInput } from "../../components";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { resetPatientsError } from "../../features/patients/patientsSlice";
import { useDispatch, useSelector } from "react-redux";
import axiosClient from "../../common/api/request";
import config from "../../config";
import Utils from "../../utils";

const { TextArea } = Input;

export default function CreatePatient({
  token,
  title,
  isOpened,
  onOk,
  onCancel,
}) {
  const { width } = useWindowDimensions();
  const { error, loading } = useSelector((state) => state?.patients);
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const dateFormat = "YYYY-MM-DD";

  const [imageUrl, setImageUrl] = useState(null);
  const [progress, setProgress] = useState(0);
  const [medImage, setMedImage] = useState(null);
  const [medProgress, setMedProgress] = useState(0);

  const postImage = async (formData) => {
    const { onSuccess, onError, file, onProgress } = formData;
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    const newFormData = new FormData();
    newFormData.append("image", file);
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/images/`,
        newFormData,
        {
          config,
          onUploadProgress: (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setProgress(percent);
            if (percent === 100) {
              setTimeout(() => setProgress(0), 1000);
            }
            onProgress({ percent: (event.loaded / event.total) * 100 });
          },
        }
      );
      setImageUrl({
        status: "done",
        id: data?.id,
        url: data?.image,
        uid: file?.uid,
        name: file?.name,
      });
      setProgress(0);
      onSuccess("Ok");
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        const errorKeys = Object.keys(error.response.data);
        const firstErrorKey = errorKeys.length > 0 ? errorKeys[0] : null;
        const errorMessage = firstErrorKey
          ? error?.response?.data[firstErrorKey]
          : "An error occurred.";
        formRef?.current?.setFields([
          {
            name: "id_image_url",
            errors: [errorMessage],
          },
        ]);
        onError({ errorMessage });
      } else {
        onError({ message });
        formRef?.current?.setFields([
          {
            name: "id_image_url",
            errors: [message],
          },
        ]);
      }
    }
  };

  const handleIdImageChange = (info) => {
    if (info?.file?.status === "removed") {
      setImageUrl(null);
      setProgress(0);
      formRef?.current?.setFields([
        {
          name: "id_image_url",
          errors: [],
        },
      ]);
    } else if (
      info?.file?.status !== "removed" &&
      info?.file?.status !== "done"
    ) {
      setImageUrl({
        uid: info?.file?.originFileObj?.uid,
        name: info?.file?.originFileObj?.name,
        status: info?.file?.status,
      });
    }
  };

  const postMedImage = async (formData) => {
    const { onSuccess, onError, file, onProgress } = formData;
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    const newFormData = new FormData();
    newFormData.append("image", file);
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/images/`,
        newFormData,
        {
          config,
          onUploadProgress: (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setMedProgress(percent);
            if (percent === 100) {
              setTimeout(() => setMedProgress(0), 1000);
            }
            onProgress({ percent: (event.loaded / event.total) * 100 });
          },
        }
      );
      setMedImage({
        status: "done",
        id: data?.id,
        url: data?.image,
        uid: file?.uid,
        name: file?.name,
      });
      setMedProgress(0);
      onSuccess("Ok");
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        const errorKeys = Object.keys(error.response.data);
        const firstErrorKey = errorKeys.length > 0 ? errorKeys[0] : null;
        const errorMessage = firstErrorKey
          ? error?.response?.data[firstErrorKey]
          : "An error occurred.";
        formRef?.current?.setFields([
          {
            name: "med_rec_image_url",
            errors: [errorMessage],
          },
        ]);
        onError({ errorMessage });
      } else {
        onError({ message });
        formRef?.current?.setFields([
          {
            name: "med_rec_image_url",
            errors: [message],
          },
        ]);
      }
    }
  };

  const handleMedImageChange = (info) => {
    if (info?.file?.status === "removed") {
      setMedImage(null);
      setMedProgress(0);
      formRef?.current?.setFields([
        {
          name: "med_rec_image_url",
          errors: [],
        },
      ]);
    } else if (
      info?.file?.status !== "removed" &&
      info?.file?.status !== "done"
    ) {
      setMedImage({
        uid: info?.file?.originFileObj?.uid,
        name: info?.file?.originFileObj?.name,
        status: info?.file?.status,
      });
    }
  };

  useEffect(() => {
    if (isOpened) {
      if (error && !loading) {
        for (let key in error) {
          formRef?.current?.setFields([
            {
              name: key, // Field name you want to set the error for
              errors: [error[key][0]], // Assuming your error has a 'message' field
            },
          ]);
        }
      }
    }
  }, [isOpened, error, loading]);

  return (
    <Modal
      style={{
        borderTop: `2px solid ${token?.colorPrimary}`,
      }}
      className="createPatient-cont"
      width={width <= 550 ? 300 : 520}
      title={title}
      open={isOpened}
      centered={true}
      destroyOnClose={true}
      okText="Save"
      okButtonProps={{
        htmlType: "submit",
        type: "primary",
        key: "submit",
        loading: loading && isOpened,
        disabled: error ? Object.keys(error)?.length !== 0 : false,
      }}
      onOk={() => {
        if (error) {
          dispatch(resetPatientsError());
        }
        formRef?.current?.submit();
      }}
      onCancel={() => {
        onCancel();
        setImageUrl(null);
        setProgress(0);
        formRef?.current?.resetFields();
        dispatch(resetPatientsError());
      }}>
      <Form
        className="createPatient-cont__form"
        ref={formRef}
        layout="vertical"
        onFinish={(values) => {
          const modifiedValues = {
            ...values,
            dob: values?.dob ? Utils?.format_date(values?.dob) : undefined,
            patient_id_exp_date: values?.patient_id_exp_date
              ? Utils?.format_date(values?.patient_id_exp_date)
              : undefined,
            rec_exp_date: values?.rec_exp_date
              ? Utils?.format_date(values?.rec_exp_date)
              : undefined,
            medical_recommendation: values?.medical_recommendation
              ? {
                  text: values?.medical_recommendation,
                }
              : undefined,
            has_medical_recommendation: values?.medical_recommendation
              ? true
              : false,
            id_image_url: imageUrl?.url ? imageUrl?.url : null,
            med_rec_image_url: medImage?.url ? medImage?.url : null,
          };
          onOk(modifiedValues, () => {
            setImageUrl(null);
            setProgress(0);
            setMedImage(null);
            setMedProgress(0);
            dispatch(resetPatientsError());
          });
        }}>
        <div className="createPatient-cont__form__formItemWrapper">
          <Form.Item
            className="createPatient-cont__form__formItemWrapper__formItem"
            name="first_name"
            rules={[
              {
                required: true,
                message: "Please enter first name.",
              },
            ]}
            label={<Typography.Text>First Name</Typography.Text>}
            colon={false}>
            <Input
              type="text"
              placeholder="first Name"
              onChange={() => {
                if (error && error["first_name"]) {
                  dispatch(resetPatientsError("first_name"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="createPatient-cont__form__formItemWrapper__formItem"
            name="last_name"
            rules={[
              {
                required: true,
                message: "Please enter last name.",
              },
            ]}
            label={<Typography.Text>Last Name</Typography.Text>}
            colon={false}>
            <Input
              type="text"
              placeholder="Last Name"
              onChange={() => {
                if (error && error["last_name"]) {
                  dispatch(resetPatientsError("last_name"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="createPatient-cont__form__formItemWrapper">
          <Form.Item
            className="createPatient-cont__form__formItemWrapper__formItem"
            name="phone_number"
            rules={[
              {
                required: true,
                message: "Please enter phone number.",
              },
            ]}
            label={<Typography.Text>Phone Number</Typography.Text>}
            colon={false}>
            <PhoneNumberInput
              onChange={() => {
                if (error && error["phone_number"]) {
                  dispatch(resetPatientsError("phone_number"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="createPatient-cont__form__formItemWrapper__formItem"
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter email.",
              },
              {
                validator: Utils?.emailValidator,
              },
            ]}
            label={<Typography.Text>Email</Typography.Text>}
            colon={false}>
            <Input
              type="text"
              placeholder="Email"
              onChange={() => {
                if (error && error["email"]) {
                  dispatch(resetPatientsError("email"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="createPatient-cont__form__formItemWrapper">
          <Form.Item
            style={{
              width: "100%",
            }}
            name="ban_reason"
            rules={[
              {
                required: true,
                message: "Please enter reason.",
              },
            ]}
            label={<Typography.Text>Reason</Typography.Text>}
            colon={false}>
            <TextArea
              style={{
                resize: "none",
              }}
              rows={3}
              placeholder="Reason"
              onChange={() => {
                if (error && error["ban_reason"]) {
                  dispatch(resetPatientsError("ban_reason"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="createPatient-cont__form__formItemWrapper">
          <Form.Item
            className="createPatient-cont__form__formItemWrapper__formItem"
            name="patient_id"
            label={<Typography.Text>ID</Typography.Text>}
            colon={false}>
            <Input
              type="text"
              placeholder="ID"
              onChange={() => {
                if (error && error["patient_id"]) {
                  dispatch(resetPatientsError("patient_id"));
                  formRef?.current?.setFields([
                    {
                      name: "patient_id",
                      errors: [], // Clear the error
                    },
                  ]);
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="createPatient-cont__form__formItemWrapper__formItem"
            name="patient_id_exp_date"
            label={<Typography.Text>Expiration Date</Typography.Text>}
            colon={false}>
            <DatePicker
              style={{
                width: "100%",
              }}
              format={dateFormat}
              allowClear={true}
              onChange={() => {
                if (error && error["patient_id_exp_date"]) {
                  dispatch(resetPatientsError("patient_id_exp_date"));
                  formRef?.current?.setFields([
                    {
                      name: "patient_id_exp_date",
                      errors: [], // Clear the error
                    },
                  ]);
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="createPatient-cont__form__formItemWrapperUpload">
          <Form.Item
            className="createPatient-cont__form__formItemWrapperUpload__formItem"
            name="id_image_url">
            <div
              style={{
                color: token?.colorPrimary,
              }}>
              <Upload
                className="createPatient-cont__form__formItemWrapperUpload__formItem__upload"
                block
                style={{
                  width: "100%",
                }}
                customRequest={postImage}
                maxCount={1}
                showUploadList={imageUrl ? true : false}
                accept="image/png, image/jpeg"
                name="image"
                fileList={imageUrl ? [imageUrl] : []}
                onChange={handleIdImageChange}>
                {imageUrl || progress > 0 ? null : (
                  <Button
                    className="createPatient-cont__form__formItemWrapperUpload__formItem__upload__btn"
                    type="default"
                    size="small"
                    block
                    icon={<UploadOutlined />}>
                    Upload ID Picture
                  </Button>
                )}
              </Upload>
              {progress > 0 ? (
                <Progress
                  percent={progress}
                  status={imageUrl?.status === "error" ? "exception" : ""}
                />
              ) : null}
            </div>
          </Form.Item>
        </div>
        <Divider
          style={{
            margin: "0px",
          }}
          type="horizontal"
        />
        <div className="createPatient-cont__form__formItemWrapper">
          <Form.Item
            className="createPatient-cont__form__formItemWrapper__formItem"
            name="medical_recommendation"
            label={<Typography.Text>Med Rec</Typography.Text>}
            colon={false}>
            <Input
              type="text"
              placeholder="ID"
              onChange={() => {
                if (error && error["medical_recommendation"]) {
                  dispatch(resetPatientsError("medical_recommendation"));
                  formRef?.current?.setFields([
                    {
                      name: "medical_recommendation",
                      errors: [], // Clear the error
                    },
                  ]);
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="createPatient-cont__form__formItemWrapper__formItem"
            name="rec_exp_date"
            label={<Typography.Text>Expiration Date</Typography.Text>}
            colon={false}>
            <DatePicker
              style={{
                width: "100%",
              }}
              format={dateFormat}
              allowClear={true}
              onChange={() => {
                if (error && error["rec_exp_date"]) {
                  dispatch(resetPatientsError("rec_exp_date"));
                  formRef?.current?.setFields([
                    {
                      name: "rec_exp_date",
                      errors: [], // Clear the error
                    },
                  ]);
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="createPatient-cont__form__formItemWrapperUpload">
          <Form.Item
            className="createPatient-cont__form__formItemWrapperUpload__formItem"
            name="med_rec_image_url">
            <div
              style={{
                color: token?.colorPrimary,
              }}>
              <Upload
                className="createPatient-cont__form__formItemWrapperUpload__formItem__upload"
                block
                style={{
                  width: "100%",
                }}
                customRequest={postMedImage}
                maxCount={1}
                showUploadList={medImage ? true : false}
                accept="image/png, image/jpeg"
                name="image"
                fileList={medImage ? [medImage] : []}
                onChange={handleMedImageChange}>
                {medImage || medProgress > 0 ? null : (
                  <Button
                    className="createPatient-cont__form__formItemWrapperUpload__formItem__upload__btn"
                    type="default"
                    size="small"
                    block
                    icon={<UploadOutlined />}>
                    Upload Med Rec Picture
                  </Button>
                )}
              </Upload>
              {medProgress > 0 ? (
                <Progress
                  percent={medProgress}
                  status={medImage?.status === "error" ? "exception" : ""}
                />
              ) : null}
            </div>
          </Form.Item>
        </div>
        <Divider
          style={{
            margin: "0px",
          }}
          type="horizontal"
        />
        <div className="createPatient-cont__form__formItemWrapper">
          <Form.Item
            className="createPatient-cont__form__formItemWrapper__formItem"
            name="dob"
            label={<Typography.Text>Date Of Birth</Typography.Text>}
            colon={false}>
            <DatePicker
              style={{
                width: "100%",
              }}
              format={dateFormat}
              allowClear={true}
              onChange={() => {
                if (error && error["dob"]) {
                  dispatch(resetPatientsError("dob"));
                  formRef?.current?.setFields([
                    {
                      name: "dob",
                      errors: [], // Clear the error
                    },
                  ]);
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="createPatient-cont__form__formItemWrapper__formItem"
            name="state"
            label={<Typography.Text>State</Typography.Text>}
            colon={false}>
            <Input
              type="text"
              placeholder="State"
              onChange={() => {
                if (error && error["state"]) {
                  dispatch(resetPatientsError("state"));
                  formRef?.current?.setFields([
                    {
                      name: "state",
                      errors: [], // Clear the error
                    },
                  ]);
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="createPatient-cont__form__formItemWrapper">
          <Form.Item
            style={{
              width: "100%",
            }}
            name="address"
            label={<Typography.Text>Address</Typography.Text>}
            colon={false}>
            <Input
              type="text"
              placeholder="Address"
              onChange={() => {
                if (error && error["address"]) {
                  dispatch(resetPatientsError("address"));
                  formRef?.current?.setFields([
                    {
                      name: "address",
                      errors: [], // Clear the error
                    },
                  ]);
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="createPatient-cont__form__formItemWrapper">
          <Form.Item
            className="createPatient-cont__form__formItemWrapper__formItem"
            name="city"
            label={<Typography.Text>City</Typography.Text>}
            colon={false}>
            <Input
              type="text"
              placeholder="City"
              onChange={() => {
                if (error && error["city"]) {
                  dispatch(resetPatientsError("city"));
                  formRef?.current?.setFields([
                    {
                      name: "city",
                      errors: [], // Clear the error
                    },
                  ]);
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="createPatient-cont__form__formItemWrapper__formItem"
            name="zipcode"
            label={<Typography.Text>Zip Code</Typography.Text>}
            colon={false}>
            <Input
              type="text"
              placeholder="Zip Code"
              onChange={() => {
                if (error && error["zipcode"]) {
                  dispatch(resetPatientsError("zipcode"));
                  formRef?.current?.setFields([
                    {
                      name: "zipcode",
                      errors: [], // Clear the error
                    },
                  ]);
                }
              }}
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}
