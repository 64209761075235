import React, { useEffect, useState } from "react";
import "./CommentSection.scss";
import {
  Button,
  Input,
  Typography,
  Dropdown,
  Spin,
  Image,
  Tooltip,
} from "antd";
import {
  DownOutlined,
  FieldTimeOutlined,
  LikeOutlined,
} from "@ant-design/icons";
import defaultImage from "../../../../assets/default_image_patient.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getComments,
  postComment,
} from "../../../../features/comments/commentsActions";
import { resetCommentsError } from "../../../../features/comments/commentsSlice";
import CanDoAction from "../../../../common/services/Permissions/Can";
import Utils from "../../../../utils";

const { TextArea } = Input;

export default function CommentSection({
  token,
  patient,
  set_is_signin_opened,
  isDetailOpened,
  openNotificationWithIcon,
}) {
  const { isAuthenticated, user } = useSelector((state) => state?.auth);
  const {
    commentsData: { results: comments, meta },
    loading,
    error,
  } = useSelector((state) => state?.comments);
  const dispatch = useDispatch();

  const arrangeOptions = [
    // {
    //   key: "best",
    //   label: "Best",
    // },
    {
      key: "created_at",
      label: "Oldest",
    },
    {
      key: "",
      label: "Reset",
    },
  ];

  const [hasFetchedComments, setHasFetchedComments] = useState(false);
  const [selectedOptionKey, setSelectedOptionKey] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [comment, setComment] = useState("");

  const getCommentsByfilter = (params) => {
    dispatch(
      getComments({
        id: patient?.id,
        page: page ? page : undefined,
        per_page: perPage ? perPage : undefined,
        order_by: selectedOptionKey ? selectedOptionKey : undefined,
        ...params,
      })
    );
  };

  const handleDropdownClick = ({ key }) => {
    setSelectedOptionKey(key);
    setPage(1);
    getCommentsByfilter({
      order_by: key ? key : undefined,
      page: 1,
    });
  };

  const onLoadMore = () => {
    setPerPage(perPage + 10);
    getCommentsByfilter({
      page,
      per_page: perPage + 10,
    });
  };

  useEffect(() => {
    if (isDetailOpened && !hasFetchedComments) {
      dispatch(
        getComments({
          id: patient?.id,
          page: 1,
          per_page: 10,
        })
      );
      setHasFetchedComments(true);
    }
  }, [dispatch, patient, isDetailOpened, hasFetchedComments]);

  useEffect(() => {
    if (isDetailOpened) {
      if (error && !loading) {
        if (typeof error === "object" && !Array.isArray(error)) {
          for (let key in error) {
            if (typeof key === "string" && typeof error[key] === "string") {
              openNotificationWithIcon("error", key, error[key]);
              break;
            }
          }
        } else {
          openNotificationWithIcon("error", "Error", "Something went wrong !");
        }
        dispatch(resetCommentsError());
      }
    }
  }, [error, loading, openNotificationWithIcon, dispatch, isDetailOpened]);

  const addComment = (params) => {
    dispatch(postComment(params))?.then((res) => {
      if (res?.type === "post_comment/fulfilled") {
        setComment("");
        getCommentsByfilter();
      }
    });
  };

  return (
    <div className="commentSection-cont">
      <CanDoAction>
        <div className="commentSection-cont__addComment">
          <div className="commentSection-cont__addComment__commentWrapper">
            <div
              style={{
                width: "35px",
                height: "35px",
              }}>
              <div
                className="commentSection-cont__commentsCont__comments__content__circle"
                style={{
                  border: `1px solid ${token?.colorSecondaryLogo}`,
                }}>
                <Image
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                  src={user?.picture_url ? user?.picture_url : defaultImage}
                  alt="u_img"
                  preview={{
                    maskClassName:
                      "commentSection-cont__commentsCont__comments__content__circle__preview", // Add a custom class to the preview mask
                  }}
                />
              </div>
            </div>
            <TextArea
              style={{
                resize: "none",
              }}
              placeholder="What do yo think ?"
              disabled={loading}
              value={comment}
              rows={3}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
          </div>
          <div className="commentSection-cont__addComment__addCont">
            <Typography.Text>
              🧐 Makers appreciate thoughtful comments
            </Typography.Text>
            <Button
              className="commentSection-cont__addComment__addCont__btn"
              type="primary"
              loading={loading}
              onClick={() => {
                if (!isAuthenticated) {
                  set_is_signin_opened(true);
                } else {
                  if (comment) {
                    addComment({
                      id: patient?.id,
                      content: comment,
                    });
                  } else {
                    openNotificationWithIcon(
                      "warning",
                      "Warning",
                      "Comment can't be blank."
                    );
                  }
                }
              }}>
              {isAuthenticated ? "Comment" : "Login To Comment"}
            </Button>
          </div>
        </div>
      </CanDoAction>
      <div className="commentSection-cont__commentsCont">
        {comments?.length ? (
          <div className="commentSection-cont__commentsCont__filters">
            <Dropdown
              className="commentSection-cont__commentsCont__filters__dropDown"
              trigger={["click"]}
              menu={{
                items: arrangeOptions,
                onClick: handleDropdownClick,
              }}>
              <div className="commentSection-cont__commentsCont__filters__dropDown__content">
                {loading ? (
                  <Spin />
                ) : (
                  <>
                    <Typography.Text>
                      {selectedOptionKey === "best"
                        ? "Best"
                        : selectedOptionKey === "created_at"
                        ? "Oldest"
                        : "Sort By"}
                    </Typography.Text>
                    <DownOutlined
                      style={{
                        fontSize: "14px",
                      }}
                    />
                  </>
                )}
              </div>
            </Dropdown>
          </div>
        ) : null}
        <div className="commentSection-cont__commentsCont__comments">
          {comments?.map((comment) => (
            <div
              key={comment?.id}
              style={{
                width: "100%",
              }}>
              <div className="commentSection-cont__commentsCont__comments__content">
                <div
                  style={{
                    width: "35px",
                    height: "35px",
                  }}>
                  <div
                    className="commentSection-cont__commentsCont__comments__content__circle"
                    style={{
                      border: `1px solid ${token?.colorSecondaryLogo}`,
                    }}>
                    <Image
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                      }}
                      src={
                        comment?.created_by?.picture_url
                          ? comment?.created_by?.picture_url
                          : defaultImage
                      }
                      alt="cw_img"
                      preview={{
                        maskClassName:
                          "commentSection-cont__commentsCont__comments__content__circle__preview", // Add a custom class to the preview mask
                      }}
                    />
                  </div>
                </div>
                <div className="commentSection-cont__commentsCont__comments__content__commentContent">
                  <Typography.Text
                    strong
                    ellipsis={{
                      tooltip: {
                        title: `${
                          comment?.created_by?.first_name
                            ? comment?.created_by?.first_name
                            : ""
                        } ${
                          comment?.created_by?.last_name
                            ? comment?.created_by?.last_name
                            : ""
                        }`,
                      },
                    }}>
                    {`${
                      comment?.created_by?.first_name
                        ? comment?.created_by?.first_name
                        : ""
                    } ${
                      comment?.created_by?.last_name
                        ? comment?.created_by?.last_name
                        : ""
                    }`}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      width: "100%",
                    }}>
                    {comment?.content ? comment?.content : ""}
                  </Typography.Text>
                  <div className="commentSection-cont__commentsCont__comments__btnWrapper">
                    <Button
                      size="small"
                      style={{
                        border: "none",
                      }}
                      icon={<LikeOutlined />}
                      onClick={() => {
                        if (!isAuthenticated) {
                          set_is_signin_opened(true);
                        } else {
                          console.log("worked comment like");
                        }
                      }}
                    />
                    <div className="commentSection-cont__commentsCont__comments__btnWrapper__dateWrapper">
                      <FieldTimeOutlined />
                      <Tooltip
                        title={Utils?.format_date_time(comment?.created_at)}>
                        <Typography.Text ellipsis>
                          {Utils?.getTimeByMeasurment(comment?.created_at)}
                        </Typography.Text>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {comments?.length < meta?.count ? (
          <div className="commentSection-cont__commentsCont__loadWrapper">
            <Button loading={loading} onClick={onLoadMore} shape="round" block>
              {`${!loading ? meta?.count - perPage : ""} More Comments`}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
