import React from "react";
import { Typography } from "antd";

export default function AppTitle({
  token,
  classNameCont,
  classNameTitle,
  onTitleClick,
}) {
  
  return (
    <div
      className={classNameCont}
      style={
        typeof onTitleClick === "function"
          ? { cursor: "pointer" }
          : { cursor: "default" }
      }
      onClick={typeof onTitleClick === "function" ? onTitleClick : null}>
      <Typography.Text
        className={classNameTitle}
        style={{
          color: token?.Typography?.colorPrimaryText,
        }}>
        Black
      </Typography.Text>
      <Typography.Text
        className={classNameTitle}
        style={{
          background: `linear-gradient(136deg, ${token?.Typography?.colorPrimaryText} 60%, ${token?.Typography?.colorSecondaryLogoText} 40%)`,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}>
        L
      </Typography.Text>
      <Typography.Text
        className={classNameTitle}
        style={{
          color: token?.Typography?.colorSecondaryLogoText,
        }}>
        ist420
      </Typography.Text>
    </div>
  );
}
